.launching {
  width: 100%;
  min-height: 85vh;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.767),
      rgba(0, 0, 0, 0.767)
    ),
    url(../../../../public/assets/dubai2.webp);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  flex-direction: column;
  h2 {
    color: white;
  }
  .btn {
    color: white;
    border: 1px solid white;
  }
}

.us {
  width: 100%;
  padding: 4rem;

  .us--container {
    &.animate {
      .img {
        animation: bounce-in 1s ease;
      }
    }
    max-width: 140rem;
    margin: 3rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
    // @media ()
    .col {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      max-width: 30rem;
      height: 50rem;

      h3 {
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
      }
      .img {
        max-width: 25rem;
        max-height: 25rem;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45),
          5px 5px 9px rgba(94, 104, 121, 0.3);
        border: 3px solid white;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-size: 1.6rem;
        text-align: center;
        color: #2e2e2e;
      }
    }
  }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

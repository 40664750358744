.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  max-width: 130rem;
  width: 100%;
}
.card {
  background: #fff;
  width: 100%;
  max-width: 35rem;
  position: relative;
  transition: all 0.3s;
  &:before {
    background-color: rgba(0, 0, 0, 0.479);
    content: "";
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .tag {
    background: #031438;
    font-size: 1.4rem;
    font-weight: 700;
    color: white;
    padding: 0.5rem 1.5rem;
    position: absolute;
    right: -1rem;
    text-transform: uppercase;
    top: 2rem;
    z-index: 50;
    &:before {
      border-bottom: 1rem solid transparent;
      border-left: 1rem solid #303775;
      bottom: 100%;
      bottom: -1rem;
      content: "";
      height: 0;
      position: absolute;
      right: 0;
      width: 0;
    }
  }
  .img {
    height: 25rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    img {
      height: 100%;
      transition: all 0.3s;
      width: 100%;
    }
    .details {
      bottom: 1rem;
      opacity: 0;
      position: absolute;
      right: 1rem;
      z-index: 50;
      background: #00000080;
      div {
        padding: 1rem 0.5rem;
        span {
          text-transform: capitalize;
          display: block;
          font-size: 1.4rem;
          color: white;

          font-weight: 600;
          line-height: 1.5;
          // text-align: center;
        }
      }
      .btn {
        border: 1px solid white;
        color: white;
      }
    }
  }
  .content {
    color: #000;
    cursor: pointer;
    padding: 1rem;
    text-transform: capitalize;
    h3 {
      font-size: 2rem;
      padding-left: 1rem;
    }
    p {
      font-size: 1.4rem;
      padding-left: 1rem;
      text-align: left;
    }
    &::after {
      color: var(--color-primary);
    }
  }
  .price {
    border-top: 1px dashed #cacaca;
    cursor: text;
    display: block;
    font-size: 3rem;
    font-weight: 700;
    padding: 1rem;
  }
  &:hover {
    background: #031846;
    &:before {
      opacity: 1;
    }
    .details {
      opacity: 1;
    }
    img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    .price,
    h3,
    p {
      color: #fff;
      position: relative;
      z-index: 25;
    }
  }
}

.action--btns {
  display: flex;
  background: rgba(0, 0, 0, 0.247);
  width: 100%;
  &__btn {
    background: transparent;
    padding: 2rem;
    width: 100%;
    border: 1px solid rgb(36, 35, 35);
    color: white;
    text-transform: capitalize;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      background: rgba(78, 78, 78, 0.575);
      .icon {
        color: var(--color-secondary);
      }
    }
    .icon {
      color: white;
      font-size: 1.8rem;
    }
  }
}
.share-btn {
  width: 100%;
  position: relative;
  .social--btns {
    &.active {
      visibility: visible;
      opacity: 1;
      bottom: 100%;
    }

    visibility: hidden;
    display: flex;
    position: absolute;
    bottom: 50%;
    opacity: 0;
    left: -1rem;
    width: 100%;
    transition: 0.5s;

    .social-icon {
      max-width: 4rem;
      max-height: 4rem;
    }
  }
}

.nav {
  width: 100vw;
  top: -100px;
  // background: linear-gradient(to bottom, transparent, #ffffff04);

  &.sticky {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.5s;
    background: var(--color-secondary);
    box-shadow: 0px 5px 5px #002a556b;
    .nav--container {
      height: 7rem;
      .nav--links .nav--links__link .sub--links {
        background: var(--color-secondary);
      }
    }
  }
  &--container {
    max-width: 130rem;
    margin: auto;
    height: 11.5rem;

    // padding: 2rem 0;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      height: 100%;
      aspect-ratio: 1/1;
      img {
        height: 100%;
        // width: 100%;
      }
    }
    .searchIconOuter {
      display: none;
      color: var(--color-primary);
      font-size: 2rem;
      &:hover {
        color: white;
      }
    }
    .nav--links {
      display: flex;
      align-items: center;
      gap: 3rem;
      // for sublink styling
      .nav--links__link {
        position: relative;
        span {
          display: flex;
          place-items: center;

          cursor: pointer;
          .arrowIcon {
            font-size: 2.5rem;
            transition: 0.2s;
            &.rotate {
              transform: rotate(180deg);
            }
          }
        }
        .sub--links {
          z-index: 100;
          visibility: hidden;
          height: auto;
          position: absolute;
          top: 0;
          opacity: 0;
          left: 0;
          background: var(--color-secondary);
          width: 9rem;
          padding: 1rem;
          transition: all 0.3s;
          a {
            font-size: 1.4rem;
            line-height: 2;

            &:hover {
              color: var(--color-primary);
            }
          }
          &.active {
            visibility: visible;
            top: 100%;
            opacity: 1;
          }
        }
        &:has(.subLinkActive) span {
          border-bottom: 2px solid white;
          &:hover {
            border-bottom: 2px solid var(--color-primary);
          }
        }
      }
      //ends here
      a,
      span {
        text-decoration: none;
        color: white;
        font-size: 1.7rem;
        text-transform: uppercase;
        &:hover {
          color: var(--color-primary);
        }
      }

      .navActive {
        border-bottom: 2px solid white;
        &:hover {
          border-bottom: 2px solid var(--color-primary);
        }
      }

      .searchIcon {
        color: white;
        font-size: 1.6rem;
        cursor: pointer;
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
}
.menu-icon {
  color: var(--color-primary);
  width: 2.2rem;
  height: 2.2rem;
  display: none;
  &:hover {
    color: white;
  }
}

@media (max-width: 1400px) {
  // .nav--container {
  //   height: 8rem;
  // }
}
@media (max-width: 750px) {
  .nav--container {
    height: 8rem;
    position: relative;
    z-index: 100;
    .searchIconOuter {
      display: inline-block;
    }
    .nav--links {
      flex-direction: column;
      position: fixed;
      top: 0;
      right: -30rem;
      height: 100vh;
      width: 30rem;
      background: #012447;
      justify-content: center;
      transition: all 0.5s;
      z-index: -1;
      .nav--links__link:last-child {
        display: none;
      }
    }

    .nav--links.active {
      right: 0;
    }
  }

  .tel span {
    display: none;
  }
  .menu-icon {
    display: inline-block;
    transition: all 0.5s;
    &.active {
      transform: rotate(180deg);
    }
  }
}

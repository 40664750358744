.guide {
  margin: 3rem 0;
  background: #031248;

  padding: 1rem;
  h3 {
    font-size: 2.5rem;
    color: white;
    text-align: center;
    font-weight: 500;
    span {
      text-transform: uppercase;
      color: var(--color-primary);
    }
  }
  p {
    font-size: 1.5rem;
    color: #c7ccdd;
    margin: 2rem;
    line-height: 1.3;
    letter-spacing: 1px;
  }
}

.contactForm {
  --toastify-color-progress-light: var(--color-primary);

  --toastify-color-light: #efeff0;
  --toastify-toast-width: max-content;

  justify-content: center;
  // background: #f8ebd5;
  box-shadow: 5px 5px 5px 5px #eadab8ad;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 40rem;
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    input,
    textarea {
      font-family: "Source Sans Pro", sans-serif;
      padding: 1rem;
      background: transparent;
      font-size: 1.4rem;
      outline: none;
      border: none;
      border-bottom: 1px solid var(--color-secondary);
    }
    label {
      text-transform: capitalize;
      font-size: 1.5rem;
      font-weight: 600;
      //   margin-top: 1rem;
    }
  }
}

.toast-font-size {
  font-size: 1.4rem;

  color: rgb(41, 40, 40);

  text-transform: capitalize;
}

.actionbar {
  // height: 5rem;
  width: 100%;
  background: rgb(3, 20, 56);
  margin: 2rem 0;

  // .barIcon {
  //   color: white;
  //   border-top: 1px solid var(--color-primary);
  //   width: 2.5rem;
  //   height: 2.5rem;
  //   cursor: pointer;
  //   display: none;
  //   &:hover {
  //     color: var(--color-primary);
  //   }
  // }

  .elements {
    width: 100%;

    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem;
    gap: 2rem;
    .element {
      min-width: 25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
    }
    span,
    .icon {
      font-size: 1.4rem;
      color: white;
    }
    .icon {
      color: grey;
      padding: 0.5rem;
      font-size: 2.5rem;
      cursor: pointer;
      &:hover {
        color: var(--color-primary);
      }
      &.active {
        border: 1px solid white;
        color: white;
      }
    }
    .banner--select__menu {
      z-index: 150;
      font-size: 1.4rem;
    }
    .banner--select__control {
      background: rgb(3, 20, 56) !important;
    }
    input.banner--select__input {
      color: white;
      font-size: 1.4rem;
    }
    .banner--select__single-value {
      color: white;
    }
    .banner--select__placeholder {
      font-size: 1.4rem;
    }
  }
}

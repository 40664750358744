.property-swiper {
  position: relative;

  .tag {
    background: #031438;
    color: white;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.4rem;
    font-weight: 700;
    height: 3rem;
    padding: 0.5rem 1.5rem;
    position: absolute;
    left: 0;
    text-transform: uppercase;
    top: 2rem;
    z-index: 50;
    &:before {
      border-bottom: 1rem solid transparent;
      border-left: 1rem solid #404a5f;
      bottom: 100%;
      bottom: -1rem;
      content: "";
      height: 0;
      position: absolute;
      left: 0;
      width: 0;
    }
  }
  .swiper-slide {
    height: 50rem;
    display: flex;
    justify-content: center;
    background: #060329;

    img {
      width: 100%;

      height: 100%;
    }
  }

  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
}

.property-slider-thumbs {
  //   display: flex;

  justify-content: center;
  //   .swiper-wrapper {
  //     width: fit-content;
  //   }
  .swiper-slide {
    // width: 10rem !important;
    cursor: pointer;
    border: 3px solid #dddbdb;
    &-thumb-active {
      border-color: var(--color-secondary);
    }
  }
  &-wrapper {
    // width: 10rem;
    height: 10rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

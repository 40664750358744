.similar--properties {
  padding: 4rem 0;
  width: 100%;
  .swiper {
    .swiper-slide {
      display: flex;

      justify-content: center;
    }
  }
  .navigateBar {
    margin-bottom: 1rem;
    display: flex;
    background: #6d6c6c;
    padding: 1rem;
    justify-content: space-around;
    align-items: center;
    .nav-arrows {
      display: flex;

      width: max-content;
      .nav-left,
      .nav-right {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 3rem;
        height: 3rem;
        &:hover {
          g {
            fill: black;
          }
        }
      }
    }
  }
}

.whatsapp {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 100;
  .wtspBtn {
    width: fit-content;
    color: var(--color-primary);
    background: #000;
    border-radius: 3rem;
    font-size: 1.5rem;
    padding: 1rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    &:hover {
      background: #222121;
      span {
        font-size: 1.49rem;
        text-decoration: underline;
      }
    }

    .icon {
      font-size: 1.8rem;
    }
  }
  .widget {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    max-width: 28rem;
    font-size: 1.4rem;
    background: white;
    border-radius: 1rem;
    opacity: 0;
    display: none;
    transition: all 0.5s;

    &.active {
      opacity: 1;
      visibility: visible;
      display: block;
    }

    margin-bottom: 2rem;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background: white;
      left: 2rem;
      bottom: -0.5rem;
      transform: rotate(45deg);
    }
    a {
      color: var(--color-primary);
      &:hover {
        text-decoration: underline;
      }
    }
    &--header {
      background: #000;
      padding: 2rem;
      position: relative;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;

      p {
        font-size: 1.6rem;
        color: white;
      }
      span {
        color: var(--color-primary);
        position: absolute;
        right: 1rem;
        top: 0;
        font-size: 3rem;
        cursor: pointer;
        &:hover {
          color: rgb(182, 178, 178);
        }
      }
    }
    &--body {
      padding: 2rem;
      a {
        width: 10rem;
        height: 10rem;
        display: block;
        margin: 0 auto;

        padding: 1rem;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #e9e9e9;
          border-radius: 50%;
          transition: 0.2s;
        }
        img {
          position: relative;

          width: 100%;
          height: 100%;
        }
        &:hover {
          &::before {
            background: var(--color-primary);
          }
        }
      }
    }
    &--footer {
      text-align: center;
      border-top: 1px solid rgb(182, 180, 180);
      padding: 1rem;
    }
  }
}

.maindiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw !important;
  padding: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.ppform {
  background-color: #f6f6f4;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 50px 50px 50px 50px;
  height: auto;
  width: 850px;
}
.ppform h1 {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 20px;
}
.ppform p {
  font-size: 20px;
  padding-bottom: 20px;
}
.ppform h3 {
  font-size: 24px;
  padding-bottom: 20px;
}
.ppform label {
  display: flex;
  padding-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: left;
}
.ppform input,
select {
  background-color: #ffffff;
  border: 1px solid #000000;
  width: 100%;
  font-size: 16px;
  min-height: 47px;
  padding: 6px 16px;
  border-radius: 4px;
  display: flex;
  margin-bottom: 20px;
}
.checkbx {
  display: flex;
  width: 33% !important;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
}
.checkbx2 {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;
}
.interestdiv {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #000000;
  padding: 6px 16px;
  border-radius: 4px;

  margin-bottom: 20px;
}
.checkbx input {
  display: inline;
  width: auto !important;
  padding: 10px;
  margin: 0;
  border: 1px solid #000000;
}
.checkbx2 input {
  display: inline;
  width: auto !important;
  padding: 10px;
  margin: 0;
  border: 1px solid #000000;
}
.ppform button {
  min-height: 40px;
  display: inline-block;
  line-height: 1;
  background-color: #69727d;
  font-size: 15px;
  padding: 12px 24px;
  border-radius: 3px;
  color: #fff;
  fill: #fff;
  text-align: center;
}
.ppform textarea {
  background-color: #ffffff;

  width: 100%;
  padding: 30px;
  border-radius: 4px;
  border: 1px solid #000000;
}
.ppform button {
  width: 100%;

  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #000000;
}

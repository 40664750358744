.area {
  width: 100vw;

  &--container {
    padding: 5rem 0;
    width: 100%;
    max-width: 130rem;
    margin: auto;
  }
  .areas {
    padding: 5rem 0;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    .box {
      padding: 1rem;
      max-width: 35rem;
      position: relative;
      height: 30rem;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      &:hover {
        &:before {
          opacity: 1;
          height: 100%;
        }
        .btn {
          opacity: 1;
          visibility: visible;
        }
      }
      .btn {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%);
        opacity: 0;
        visibility: hidden;
        color: white;
        transition: all 0.3s;
        border: 1px solid white;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0%;
        background: #03124181;
        opacity: 0;
        transition: all 0.3s;
      }
      .img {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-size: 1.5rem;
        text-transform: uppercase;
        text-align: center;
        margin-top: 1rem;
      }
    }
  }
}

.searchModal {
  position: fixed;
  overflow: hidden;

  z-index: 200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .backdrop {
    background: #000000e1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .form--container {
    position: relative;
    .close {
      cursor: pointer;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      font-size: 2.5rem;
      color: white;
      z-index: 299999999999;
      &:hover {
        color: var(--color-primary);
      }
    }
    .form {
      // background: rgb(0, 0, 0);
      background: rgb(3, 20, 56);
      animation: none;
    }
  }
}

.listcards {
  width: 100%;
  max-width: 110rem;
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 3rem;

  .listcard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background: #f5e4cb;
    @media (max-width: 600px) {
      flex-direction: column;
    }

    .img {
      position: relative;
      transition: all 0.3s;
      overflow: hidden;
      height: 30rem;
      min-width: 30rem;
      max-width: 30rem;
      img {
        width: 100%;
        height: 100%;
      }

      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(0, 0, 0, 0.479);
        z-index: 10;
        opacity: 0;
        cursor: pointer;
      }

      .tag {
        position: absolute;
        right: -1rem;
        top: 2rem;
        font-size: 1.4rem;
        height: 3rem;
        background: #af8d1e;
        text-transform: uppercase;
        font-weight: 700;
        padding: 0.5rem 1.5rem;
        z-index: 50;

        &::before {
          content: "";
          position: absolute;
          height: 0px;
          width: 0px;
          bottom: 100%;
          right: 0;
          bottom: -1rem;
          border-bottom: 1rem solid transparent;
          border-left: 1rem solid #4d3b03;
        }
      }

      img {
        height: 100%;
        width: 100%;
        transition: all 0.3s;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
        img {
          transform: scale(1.1);
        }
      }
    }
    .right {
      // max-width: 70%;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-transform: capitalize;

      a {
        width: max-content;
      }
      gap: 2rem;
      .row {
        // background: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-bottom: 1px dashed rgb(202, 202, 202);
        padding: 1rem 0;
        h3 {
          font-size: 2rem;
        }
        p {
          text-align: left;
          font-size: 1.6rem;
        }

        .price {
          padding: 1rem;
          font-size: 3rem;
          font-weight: bold;
          width: max-content;
          white-space: nowrap;
        }
      }
      p {
        font-size: 1.5rem;
        line-height: 1.4;
        color: rgb(70, 69, 69);
        &.bold {
          font-weight: bold;
        }
      }
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  .pagination-item {
    padding: 0 1rem;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: #000000de;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(83, 82, 82, 0.43);
    font-size: 1.4rem;
    width: 32px;

    &.dots {
      border: none;
      &:hover {
        background-color: transparent;
        cursor: default;
        border: none;
        color: initial;
      }
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border: 1px solid var(--color-primary);
      color: var(--color-primary);
      cursor: pointer;
    }

    &.selected {
      background-color: var(--color-primary);
      color: white;
      border: none;
    }

    .arrow {
      &.right {
        transform: rotate(180deg);
      }
    }

    &.disabled {
      pointer-events: none;
      border: 1px solid rgba(0, 0, 0, 0.158);
      //   display: none;

      .arrow {
        color: rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.developers {
  width: 100%;
  padding: 5rem 0;
  background-color: #f6f6f6;
  .dCards {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    max-width: 130rem;
    margin: 3rem auto;
    flex-wrap: wrap;
    transition: 0.5s all;
    .dcard {
      box-shadow: 5px 5px 5px 5px #eadab8ad;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;
      justify-content: center;
      padding: 3rem;
      border-radius: 1rem;
      height: 50rem;
      .img {
        width: 100%;
        max-width: 30rem;
        height: 25rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 1rem;
        }
      }
      .brand {
        img {
          width: 100%;
        }
      }
      &:hover {
        box-shadow: inset 5px 5px 5px 5px #eadab8ad;
      }
    }
  }
}

.footer {
  width: 100vw;
  background: rgb(3, 20, 56);
  background: #d8d6d6;

  span {
    display: block;
    font-size: 1.4rem;
    text-align: left;
    color: rgb(46, 46, 46);
    text-align: center;
  }
  &--container {
    max-width: 130rem;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 5rem 1rem;

    gap: 5rem;
    @media (max-width: 650px) {
      flex-wrap: wrap;
    }
    .col {
      padding: 1rem;
      max-width: 30rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      //   align-items: center;
      gap: 2rem;
      h3 {
        color: var(--color-secondary);
        font-size: 1.8rem;
        text-transform: uppercase;
        font-weight: 600;
      }
      p {
        font-size: 1.5rem;
        color: rgb(46, 46, 46);
      }
      h4 {
        font-size: 1.6rem;
        text-transform: capitalize;
        color: rgb(46, 46, 46);
        font-weight: 700;
      }
      a {
        display: inline-block;
        font-size: 1.5rem;
        color: rgb(46, 46, 46);
        cursor: pointer;
        &:hover {
          color: rgb(119, 119, 119);
          text-decoration: underline;
        }
      }
      .social {
        padding: 1rem;
        gap: 2rem;
        display: flex;
        .icon {
          color: rgb(46, 46, 46);
          font-size: 2.5rem;
          cursor: pointer;
          &:hover {
            color: white;
          }
        }
      }
    }
  }
}

.form {
  background: rgba(248, 248, 248, 0.415);
  width: max-content;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  animation: form-wobble-reverse 1s linear;
  animation-delay: 1.6s;
  animation-fill-mode: both;
  transform-origin: 50% 100%;
  &--btn {
    color: #222;
    background: var(--color-primary);
    outline: none;
    border: none;
    padding: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    position: relative;
    cursor: pointer;
    transition-duration: all 0.4s;
    -webkit-transition-duration: 0.4s;
    box-shadow: 0 5px 2px 0 #db9231;
    &:hover {
      transition-duration: 0.1s;
      background-color: #ebba7b;
    }

    &:active {
      top: 1px;
      box-shadow: inset 0 2px 2px 0 #db9231;
    }
  }

  &--group {
    width: 30rem;
    label {
      color: var(--color-secondary);
      display: block;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.3;
      margin: 1rem 0;
    }
    .banner--select__control {
      background: rgba(255, 255, 255, 0.7);
      box-shadow: none;
      border: none;
      border-radius: 0;
      &:hover {
        box-shadow: 0 0 0 1px var(--color-primary);
      }
    }
    .banner--select__input-container {
      font-size: 1.4rem;
    }
    .banner--select__dropdown-indicator {
      color: rgb(51, 51, 51);
    }
    .banner--select__indicator-separator {
      background: rgb(51, 51, 51);
    }
    .banner--select__clear-indicator {
      color: rgb(51, 51, 51);
    }
    .banner--select__placeholder {
      font-size: 1.2rem;
      text-transform: uppercase;
      color: rgb(51, 51, 51);
    }
    .banner--select__menu {
      font-size: 1.2rem;
    }
    .banner--select__multi-value {
      background: #ecaf5f65;
    }
    .banner--select__multi-value__label {
      font-size: 1.2rem;
    }
    .banner--select__single-value {
      font-size: 1.4rem;
    }
  }
}
@keyframes form-wobble-reverse {
  0% {
    -webkit-transform: none;
    transform: none;
  }

  15% {
    -webkit-transform: translate3d(25%, 0, 0) rotate3d(0, 0, 1, 5deg);
    transform: translate3d(25%, 0, 0) rotate3d(0, 0, 1, 5deg);
  }

  30% {
    -webkit-transform: translate3d(-20%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-20%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  45% {
    -webkit-transform: translate3d(15%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(15%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  60% {
    -webkit-transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-10%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }

  75% {
    -webkit-transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 1deg);
    transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 1deg);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}

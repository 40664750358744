.listsec {
  width: 100%;
  padding: 4rem;
  background-color: #f6f6f6;

  .listsec--container {
    &.animate {
      .img {
        animation: bounce-in 1s ease;
      }
    }
    width: 50%;
    margin: 3rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    flex-wrap: wrap;
    padding: 30px;
    background: rgba(248, 248, 248, 0.415);
    color: #031438;

    border: none;
    width: 100%;
    height: 400px;
    max-width: 80%;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.544),
        rgba(0, 0, 0, 0.267)
      ),
      url(https://www.aspect-global.com/assets/bg_img.jpg);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;
    box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45),
      5px 5px 9px rgba(94, 104, 121, 0.3);

    // @media ()
    .col {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      max-width: 48%;
      height: auto;
      text-align: left;

      h3 {
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
        text-align: left;
      }
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right;
        border-radius: 30px;

        box-shadow: -5px -5px 9px rgba(255, 255, 255, 0.45),
          5px 5px 9px rgba(94, 104, 121, 0.3);
        border: 3px solid white;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-size: 1.6rem;
        text-align: left;
        color: #2e2e2e;
        text-align: justify;
      }
      .listbtn {
        background: #031438;
        margin: 20px;
        color: #ffff;
        padding: 15px 30px;
        font-size: 20px;
      }
      h1 {
        font-size: 28px;
        text-shadow: 5px 5px 5px #f6f6f6;
      }
    }
  }
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  overflow-x: hidden;
  // scroll-behavior: smooth;
  font-family: "Source Sans Pro", sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

:root {
  --color-primary: #f0ac24;
  --color-primary-darker: #ac760a;
  --color-secondary: #031438;

  --color-bg: #f6f6f6;
}
.header-top {
  position: absolute;
  top: 0;
  left: 0;
}

h2 {
  font-size: 4.6rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}

.btn {
  cursor: pointer;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  border: none;
  background-color: transparent;
  border: 2px solid var(--color-secondary);
  color: var(--color-secondary);
  letter-spacing: 1px;
  width: max-content;
  font-weight: 600;
  font-family: "source sans pro", sans-serif;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  &:active {
    filter: brightness(80%);
  }

  &:before {
    z-index: -1;
    background: var(--color-secondary);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 0%;

    transition: 0.3s;
  }
  &:hover {
    color: white;
    &:before {
      height: 100%;
    }
  }
}

.bg-Change {
  .nav {
    background: #031438;
  }
  .topbar {
    background: rgb(100, 115, 151);
    a.tel {
      color: white;
    }
    .social .icon {
      color: white;
      &:hover {
        color: var(--color-secondary);
      }
    }
    .switch span {
      color: white;
    }
    .select {
      color: white;
      select {
        color: rgb(184, 180, 180);
      }
    }
  }
}

.properties {
  width: 100%;
  background-color: #f6f6f6;
  &--container {
    padding: 5rem 0;
    max-width: 130rem;
    margin: auto;
  }
}

.chips {
  // background: yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 1rem;
  .chip {
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    border: 1px solid var(--color-primary);
    font-size: 1.4rem;
    text-transform: capitalize;
    span {
      border-radius: 50%;
      background: var(--color-primary);
      padding: 5px;
      margin-left: 5px;
      font-size: 1rem;
      color: white;
      cursor: pointer;
      &:hover {
        color: grey;
      }
    }
  }
}
.search {
  text-align: center;
  font-size: 1.4rem;
  text-transform: capitalize;
  font-weight: 700;
  display: flex;

  justify-content: center;
  // span:not(:first-child) {
  //   border-left: 1px solid var(--color-primary);
  // }
  a,
  span {
    padding: 0 1rem;
  }
  span:not(:first-child),
  a {
    border-left: 1px solid var(--color-primary);
    color: black;
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
    }
  }
}

.errorMessage {
  color: red;
  font-size: 1.3rem;
  text-transform: capitalize;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 40px;
  height: 40px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--color-primary);
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

@media (max-width: 1400px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 750px) {
  html {
    font-size: 50%;
  }
}

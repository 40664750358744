.featured {
  width: 100vw;
  background: #f6f6f6;

  padding: 5rem 0;
  .sub {
    margin: 2rem 0;
    font-size: 1.4rem;
    text-align: center;
  }
  .tabs {
    height: 5rem;

    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    text-transform: uppercase;
    color: grey;
    &--tab {
      cursor: pointer;
      &:hover {
        color: var(--color-primary);
      }
    }
    &--tab.active {
      color: black;
      font-weight: 500;
      position: relative;
      padding: 0.5rem 0;
      &:hover {
        color: var(--color-primary);
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0;
        bottom: 0;
        background-color: #000;
      }
    }
  }
  .tabs--content {
    max-width: 130rem;
    margin: 0 auto;
  }
}

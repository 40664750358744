.contact {
  width: 100%;
  background-color: #f6f6f6;

  &--container {
    padding: 5rem 1rem;
    width: 100%;
    max-width: 130rem;
    margin: auto;
    .content {
      padding: 4rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5rem;
      @media (max-width: 800px) {
        flex-wrap: wrap;
      }
    }
    h3 {
      font-size: 4rem;
      margin: 1rem 0;
      text-transform: capitalize;
    }
    h4 {
      text-transform: capitalize;
      font-size: 2.5rem;
      color: var(--color-primary);
      font-weight: 600;
      margin-bottom: 2rem;
      text-decoration: underline;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
    ul li a,
    ul li {
      font-size: 1.8rem;
      color: rgb(48, 47, 47);

      display: flex;
      align-items: center;
      gap: 1rem;
      .icon {
        color: var(--color-primary);
      }
    }
    a:hover {
      color: var(--color-primary);
      text-decoration: underline;
    }
  }
}
